import React, {useContext, useRef, useState} from 'react';
import Classes from './login.module.css';
import {ReactComponent as Caution} from '../../assets/caution.svg';
import {ReactComponent as Close} from '../../assets/closeEye.svg';
import Loading from '../../components/loading/loading';
import {MainContext} from '../../App';
import axios from 'axios';
import toast from 'react-hot-toast';
import {Link} from 'react-router-dom';
import Input from '../../components/input/input';

const TwofaLogin = () => {
  const CTX = useContext(MainContext);
  const usernameInputRef = useRef();
  const [laebl, setLaebl] = useState(false);
  const [passward, setPassward] = useState(false);
  const [inputs, setInputs] = useState({username: '', password: ''});
  const [userErr, setUserErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');

  const onChangeHandler = (name, e) => {
    // setLaebl(true)
    const spread = {...inputs};
    spread[name] = e.target.value;
    setInputs(spread);
    if (spread[name].length < 1) {
      setLaebl(false);
      setPassErr(true);
    } else {
      setLaebl(true);
      setPassErr(false);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    // CTX.setToken({username: inputs.username});
    // CTX.setUser({password: inputs.password});

    try {
      const url = await axios.post(`${CTX.url}user/account/2fa/login`, {
        _id: sessionStorage.getItem('_id'),
        twofa: inputs.password,
        terms: true,
      });
      setLoading(false);
      if (url.data.error) {
        return toast(url.data.msg);
      }
      
      // console.log('CTX', url.data);

      CTX.setToken(url.data.token);
      CTX.setUser(url.data.data);
    } catch (error) {
      console.log('error', error.response);
      setLoading(false);
      if (error.response.status === 401 || error.response.status === 404) {
        return toast(error.response.data.msg);
      }
      toast('Check your internet');
    }
  };

  return (
    <>
      <div className={Classes.mainImage}></div>

      <div className={Classes.fadeImg}></div>
      <div className={Classes.mainframe}>
        <nav className={Classes.navSection}>
          <h2>MTN PROFITS</h2>

          <img
            alt=" "
            src={require('../../assets/logo.png')}
            className={Classes.imageSection}
          />
        </nav>

        {/* <div className={Classes.}></div> */}

        <div className={Classes.heroSideLoginSection}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div className={Classes.warningCover}>
              <Caution fill="#b42d19" />
              <div className={Classes.ErrorMessageText}>
                Check the url make sure its this{' '}
                <span style={{fontWeight: 'bold'}}>hello-world.com</span>. This
                is in other to prevent losing your account.
              </div>
            </div>

            <h1 className={Classes.salutationTitle}>
              Two-factor Authentication (2FA) Login
              <span className={Classes.FloatingPage}>
                Enter your 2FA password and continue
              </span>
            </h1>
          </div>
          <form autoComplete="off" onSubmit={onSubmitHandler}>
            <div className={Classes.mainInputCover}>
              {/* <Input
                style={{color: 'rgb(59, 51, 49)'}}
                inputs={inputs}
                setInputs={setInputs}
                htmlFor="username"
                require
                label="Username"
              /> */}
              <div style={{height: '32px'}}></div>
              <div className={Classes.eachMainInputCover}>
                <label
                  style={{
                    color: passErr ? '#b42d19' : 'rgba(109, 114, 139, 1)',
                    fontSize: '15px',
                    display: 'flex',
                    lineHeight: laebl ? '0.8125rem' : '1.4375rem',
                    margin: laebl ? '8px 0 -8px 8px' : '32px 0 -32px 8px',
                  }}
                  className={Classes.onlyBorderLabel}
                >
                  {passErr && (
                    <Caution
                      fill="#b42d19"
                      style={{width: '13px', marginRight: '2px'}}
                    />
                  )}
                  Password
                </label>
                <input
                  type={showPass ? 'text' : 'password'}
                  onClick={() => setPassward(true)}
                  onChange={onChangeHandler.bind(this, 'password')}
                  autoComplete="hjcyurjuy"
                  name="password"
                  required
                  value={inputs.passsword}
                  className={Classes.onlyBorderInput}
                />
                <Close
                  onClick={() => setShowPass(!showPass)}
                  className={Classes.passSVG}
                />
              </div>

              <label
                style={{
                  fontSize: '13px',
                  display: 'flex',
                  lineHeight: laebl ? '0.8125rem' : '1.4375rem',
                  margin: '8px 0 -8px 13px',
                }}
                className={Classes.onlyBorderLabel}
              >
                Hint: {sessionStorage.getItem('hint')}
              </label>

              <div
                style={{
                  width: 'max-content',
                  margin: 'auto',
                  marginTop: '40px',
                }}
              ></div>

              {/* <div className={Classes.saveCheckBox}>
                <input type="checkbox" />
                <label style={{color: '#888'}}>Save details</label>
              </div> */}
              <button
                disabled={userErr || passErr || loading}
                className={Classes.signOnBTN}
              >
                {' '}
                {loading ? (
                  <Loading
                    fill="#888"
                    size="23px"
                    style={{marginTop: '22px', marginLeft: '17px'}}
                  />
                ) : (
                  <span>Sign in</span>
                )}
              </button>
            </div>
          </form>

          <div
            className={Classes.mainInputCover}
            style={{display: 'flex', marginTop: '30px', paddingBottom: '30px'}}
          >
            <Link to="/register">
              <div className={Classes.forgotDetails}>Create Account?</div>
            </Link>
            <div className={Classes.forgotDetails} style={{marginLeft: 'auto'}}>
              Forgot Password?
            </div>
          </div>
        </div>
        <div style={{height: '100px'}}></div>
      </div>
    </>
  );
};

export default TwofaLogin;
