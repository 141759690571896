import React, {useContext} from 'react';
import {MainContext} from '../../App';
import {ReactComponent as ArrowDark} from '../../assets/arrow-dark.svg';
import {ReactComponent as LogoWhite} from '../../assets/logo-white.a2292549.svg';
import {ReactComponent as Logout} from '../../assets/logout.svg';
import '../../index.css';
import Classes from './sidebar.module.css';
import {Link, NavLink} from 'react-router-dom';

const SideDrawer = () => {
  const CTX = useContext(MainContext);
  return (
    <div className="block absolute xl:hidden">
      <div className="fixed inset-y-0 right-0 flex z-50">
        <div className="fixed inset-0 enter-done">
          <div className="absolute inset-0 opacity-75"></div>
        </div>
        <div className="relative flex-1 flex flex-col w-screen enter-done">
          <div className="absolute top-0 right-0 -mr-0 p-1 z-40">
            <button
              type="button"
              onClick={CTX.setSideDrawer}
              className="flex flex-col items-center justify-center h-12 w-12 rounded-full focus:outline-none z-10"
              aria-label="Close sidebar"
            >
              <ArrowDark className="w-4 text-white" />
            </button>
          </div>
          <div className="relative h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto bg-background">
            <div className="flex items-start justify-start flex-shrink-0 px-4">
              <Link className="active" to="/portfolio">
                <img
                  style={{width: '200px'}}
                  src={require('../../assets/logo.png')}
                  alt="logo"
                />
              </Link>
            </div>
            <nav className="mt-5 flex-1 bg-background overflow-x-hidden w-full hidden lg:block">
              <NavLink
                onClick={CTX.setSideDrawer}
                className={Classes.navLinkCover}
                to="/portfolio"
              >
                <button
                  type="button"
                  className="
                  text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Portfolio
                </button>
              </NavLink>

              <NavLink
                onClick={CTX.setSideDrawer}
                className={Classes.navLinkCover}
                to="/add/bank"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Bank
                </button>
              </NavLink>

              <NavLink
                className={Classes.navLinkCover}
                onClick={CTX.setSideDrawer}
                to="/rewards"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Rewards
                </button>
              </NavLink>

              <NavLink
                className={Classes.navLinkCover}
                onClick={CTX.setSideDrawer}
                to="/profile"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Profile
                </button>
              </NavLink>

              <NavLink
                className={Classes.navLinkCover}
                onClick={CTX.setSideDrawer}
                to="/transactions?tab=pending"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Transactions
                </button>
              </NavLink>


              <NavLink
                className={Classes.navLinkCover}
                onClick={CTX.setSideDrawer}
                to="/account/notifications"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Notifications
                </button>
              </NavLink>

              <div></div>
            </nav>

            <nav className="mt-5 flex-1 bg-background overflow-x-hidden w-full block lg:hidden xl:hidden">
            <NavLink
                onClick={CTX.setSideDrawer}
                className={Classes.navLinkCover}
                to="/portfolio"
              >
                <button
                  type="button"
                  className="
                  text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Portfolio
                </button>
              </NavLink>

              <NavLink
                onClick={CTX.setSideDrawer}
                className={Classes.navLinkCover}
                to="/add/bank"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Bank
                </button>
              </NavLink>

              <NavLink
                className={Classes.navLinkCover}
                onClick={CTX.setSideDrawer}
                to="/rewards"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Rewards
                </button>
              </NavLink>

              <NavLink
                className={Classes.navLinkCover}
                onClick={CTX.setSideDrawer}
                to="/profile"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Profile
                </button>
              </NavLink>

              <NavLink
                className={Classes.navLinkCover}
                onClick={CTX.setSideDrawer}
                to="/transactions?tab=pending"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Transactions
                </button>
              </NavLink>


              <NavLink
                className={Classes.navLinkCover}
                onClick={CTX.setSideDrawer}
                to="/account/notifications"
              >
                <button
                  type="button"
                  className="
                                                                    text-body-text mt-4 group w-full flex items-end justify-end px-4 py-2 text-2xl leading-5 font-medium focus:outline-none transition ease-in-out duration-150
                                                              "
                >
                  Notifications
                </button>
              </NavLink>


            </nav>
            <div className="px-2 py-2 overflow-hidden">
              <button
                onClick={CTX.logout}
                type="button"
                className="mt-4 w-full group flex flex-row items-center justify-end px-2 py-2 text-2xl leading-5 font-medium text-header-text hover:text-body-text hover:bg-gray-700 focus:outline-none focus:text-body-text focus:bg-secondary transition ease-in-out duration-150"
              >
                Log out
                <Logout className="ml-4 h-6 w-6 text-vivid-blue-500 group-hover:text-gray-300 group-focus:text-gray-300 transition ease-in-out duration-150" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideDrawer;
